import { acceptHMRUpdate, defineStore } from 'pinia'
import storage from '~/assets/storage'

export const useUserStore = defineStore('user', () => {
  const hasLogin = ref(false)
  const userInfo = ref({})
  const userList = ref([])
  const alertLogin = ref(false)

  async function initStoreData() {
    userInfo.value = await storage.getUserInfo()
    userList.value = await storage.getUserList()
    hasLogin.value = await storage.getHasLogin()
  }

  onMounted(() => {
    if (process.client) {
      initStoreData()
    }
  })

  function login(user) {
    userInfo.value = user.list[user.index]
    userList.value = user.list
    hasLogin.value = true
  }

  function logout() {
    hasLogin.value = false
  }

  function setAlertLogin(is_alert) {
    alertLogin.value = is_alert
  }

  return {
    setAlertLogin,
    login,
    hasLogin,
    logout,
    alertLogin,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
